import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import favicon16 from '../images/favicon16.png'
import favicon32 from '../images/favicon32.png'
import GlobalStyle from '../styles/global'
import MainStyle from '../styles/main'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Nav from './Nav/Nav'
import Footer from './FooterComponents/Footer'

export default class Template extends React.Component {
  
  render() {
    return (
      <div className="siteRoot">
        <MainStyle />
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/ifg6pco.css"/>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
        </Helmet>
  
        <ThemeProvider theme={theme}>
          <>
            <div id="top"/>
            <Nav/>
            <div className="siteContent">
              {this.props.children}
            </div>
            <Footer />
          </>
        </ThemeProvider>
      </div>
    )
  }
}
