import React from 'react';
import styled from 'styled-components';

const CR = styled.div`
text-align: center;
font-size: 12px;
color: #66686A;
padding-bottom: 18.75rem;
@media (max-width: 768px) {
  padding-bottom: 4rem;
}
`

class Copyright extends React.Component {

  render() {
    const dt = new Date();
    const currentYear = dt.getFullYear()
    
    if (currentYear === 2018) {
      return <CR>© Reed's Market, 2018</CR>
    } else {
      return <CR>{`© Reed's Market, 2018-${currentYear}`}</CR>
    }
  }
}

export default Copyright 