import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 3.75rem;
  padding-bottom: 8.75rem;
  width: 1378px !important;
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  @media (max-width: 768px) {
    flex-flow: column wrap;
  }
`

const Item = styled.div`
  padding: 0 !important;
  margin-right: 1.6875em;
  @media (max-width: 768px) {
    margin-left: 2.1875em;
    margin-right: 0;
    margin-bottom: 1.6875em;
  }
  h4 {
    width: max-content;
    font-size: 1rem;
    color: ${props => props.theme.colors.base};
    margin-bottom: 1.0625rem;
  }
  p {
    width: max-content;
    font-size: .875rem;
    color: ${props => props.theme.colors.quadiary};
    margin-bottom: 0;
  }
  @media (max-width: 1250px) {
    &:first-child {
      margin-left: 2.1875em;
    }
    flex: 0 1 auto !important;
  }
  @media (min-width: 1250px) {
    &:first-child {
      margin-left: 2.1875em;
    }
    flex: 0 1 auto !important;
    &:nth-last-child(2) {
      flex: 1 1 auto !important;
      @media (max-width: 768px) {
        margin-left: 2.1875em;
      }
    }
    &:nth-last-child(1) {
      margin-right: 140px;
    }    
  }
  @media (min-width: 1400px) {
    flex: 0 1 auto !important;
    &:nth-last-child(2) {
      flex: 1 1 auto !important;
      @media (max-width: 768px) {
        margin-left: 2.1875em;
      }
    }
    &:nth-last-child(1) {
      margin-right: 140px;
    }
  }
`

const SubFooter = (props) => {
  const stores = props.stores.allContentfulStore.edges.map(({node}, index) => (
    <Item key={index} className={"store"}>
        <h4>{node.localName}</h4>
        <p>{node.storeName}</p>
        <p>{`(${node.phoneNumber.toString().slice(0,3)}) ${node.phoneNumber.toString().slice(3,6)}-${node.phoneNumber.toString().slice(6)}`}</p>
        <p>{node.addressLine1}</p>
        <p>{node.addressLine3}</p>
    </Item>
  ))
  return (
    <Wrapper>
      <List id={'storeLocations'}>
        {stores}
        <Item sm={{span: 3}} className={'get-help'}>
          <h4>Get Help</h4>
          <p>Store Locations & Hours</p>
          <p><a href={"mailto:help@reeds.market"}>help@reeds.market</a></p>
          

        </Item>
      </List>
    </Wrapper>
  )
}
  
  export default SubFooter
