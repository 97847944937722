import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Modal, Navbar, Nav } from 'react-bootstrap';
import config from '../../utils/siteConfig'
import theme from '../../styles/theme'
import hamburger from '../../images/menu.svg'
import { globalHistory } from "@reach/router"

const Header = styled.header`
  background: ${props => props.theme.colors.white};
  width: 100%;
  padding: 1.5em 0;
  @media only screen and (max-width: 768px) {
    top: 0;
    z-index: 1000;
    position: fixed;
    padding: 28px 0 10px 0;
    #responsive-navbar-nav {
      display: none;
    }
  }
  border-bottom: 2px solid ${props => props.theme.colors.base}; /* This creates the border. */
`
const NavLink = styled(Link)`
  @media only screen and (max-width: 768px) {
    font-size: 1.375rem !important;
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.navHighlight};
    }
    text-transform: uppercase;
  }
  color: ${props => props.theme.colors.base};
  text-decoration: none;
  font-weight: 700 !important;
  display: block;
  padding: .5rem 1rem;
  transition: all 0.2s;
  &:hover {
    color: ${props => props.theme.colors.navHighlight};
  }
`

const CollapseableNav = styled(Navbar.Collapse)`
  justify-content: flex-end;
  @media only screen and (max-width: 475px) {
    display: none !important;
  }
`

const CollapseableNavToggle = styled(Navbar.Toggle)`
  .navbar-toggler-icon {
    display: none;
  }
  @media screen and (max-width: 991px) {
    display: block;
    top: 50px;
    right: 50px;
    width: 70px;
    height: 9.75vw;
    background-image: url('${hamburger}') !important;
    background-size: 9.75vw;
    background-position: center; 
    background-color: transparent;
    background-repeat: no-repeat;
    color: transparent !important;
    border-color: transparent !important;
  }
  @media screen and (max-width: 475px) {
    display: block;
    position: sticky;
    top: 50px;
    right: 0px;
    width: 70px;
    height: 9.75vw;
    background-image: url('${hamburger}') !important;
    background-size: 9.75vw;
    background-position: center; 
    background-color: transparent;
    background-repeat: no-repeat;
    color: transparent !important;
    border-color: transparent !important;
  }
  display: none;
`

const StyledNavBar = styled(Navbar)`
  position: relative;
  max-width: 73.25rem;
  margin: 0 auto;
  @media screen and (max-width: 475px) {
    &.navbar {
      position: sticky;
      padding: 0 1rem !important;
    }
  }
`

const StyledNav = styled(Nav)`
  display: flex;
  float: left;
  flex-flow: column;
  justify-content: flex-end;
  @media screen and (min-width: 991px) {
    flex-flow: row;
  }
`
const Brand = styled.img`
  max-width: 50vw;
  @media screen and (max-width: 768px) {
    max-width: 164px !important;
    max-height: 46px !important;
  }
`
const StyledModal = styled(Modal)`

`
const StyledModalBody = styled(Modal.Body)``

const StyledNavBarBrand = styled(Navbar.Brand)`
@media screen and (max-width: 768px) {
  padding: 0 !important;
  max-width: 164px !important;
  max-height: 46px !important;
}
`

const activeLinkStyle = {
  color: `${props => props.theme.colors.navHighlight}`,
}

class Menu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleToggle = () => {
      if (this.state.show) {
        this.handleHide()
      } else {
        this.handleShow()
      }
    };

    this.handleShow = () => {
      let html = document.getElementsByTagName("html")[0];
      html.style.overflow = "hidden";
      this.setState({ show: true });
    };

    this.handleHide = () => {
      let html = document.getElementsByTagName("html")[0];
      html.style.overflow = "";
      this.setState({ show: false });
    };

    this.handleScroll = (e) => {
      if (this.state.show) {
        e.preventDefault();
      }
    };

    this.handleExit = (e) => {
      let html = document.getElementsByTagName("html")[0];
      html.style.overflow = "";
      const targetPath = e.target.pathname.replace(/\//g, '')
      const currentPath = globalHistory.location.pathname.replace(/\//g, '')
      if (targetPath === currentPath) {
        this.handleHide()
      }
    };
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.handleScroll, {
        passive: false
    });
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleScroll);
  }

  render(){
    // const navData = [{"/": "Home"}, {"/ads": "Ads"}, {"/rewards": "Reed's Rewards"}, {"/contact": "Contact"}]
    const navData = [{"/": "Home"}, {"/ads": "Ads"}, {"/coupons": "Coupons"}, {"/contact": "Contact"}]
    const navLinks = navData.map((data, index) => (
      <NavLink key={index} to={Object.getOwnPropertyNames(data)[0]} activeStyle={activeLinkStyle} onClick={this.handleExit}>{Object.values(data)[0]}</NavLink>
    ))
    return (
      <Header onScroll={this.handleScroll} onTouchMove={this.handleScroll} onWheel={this.handleScroll}>
        <StyledNavBar collapseOnSelect onToggle={this.handleToggle} expand="lg">
          <StyledNavBarBrand>
            <Link to="/">
              <Brand
                alt="Reed's Market"
                src={config.siteLogo}
                className="d-inline-block align-top"
              />
            </Link>
          </StyledNavBarBrand>
          <CollapseableNavToggle className={'hamburger-menu-icon'} aria-controls="responsive-navbar-nav" onClick={this.handleToggle}/>
          <CollapseableNav expand="lg" id="responsive-navbar-nav">
            <StyledNav>
              {navLinks}
            </StyledNav>
          </CollapseableNav>
        </StyledNavBar>
        <StyledModal
          show={this.state.show}
          onHide={this.handleToggle}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton/>
          <StyledModalBody>
            <StyledNav>
              {navLinks}
            </StyledNav>
          </StyledModalBody>
        </StyledModal>
      </Header>
    )
  }
}

export default Menu
