const dt = new Date();
const copyright = `© Reed's Market, 2018-${dt.getFullYear()}`

module.exports = {
  siteTitle: 'Reed\'s Market',
  siteTitleAlt: 'Reed\'s Market', // This allows an alternative site title for SEO schema.
  publisher: 'Reed\'s Market', // Organization name used for SEO schema
  siteDescription:
    'We carry the best produce, deli, and meats in all of north Mississippi. Here, you will find quality products, everyday value prices, and exceptional service.',
  siteUrl: 'https://reeds.market', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Reed\'s Market', // Author for RSS author segment and SEO schema
  authorUrl: 'https://reeds.market/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@Reeds_Market', // Change for Twitter Cards
  shortTitle: 'Reed\'s Market', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/logo_w_tagline@2x.png', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 600, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: `${copyright}`, // Copyright string for the RSS feed
};