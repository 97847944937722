import { createGlobalStyle } from 'styled-components';
import theme from './theme';
const MainStyle = createGlobalStyle`

@charset "utf-8";

/*
This is compiled file, for the original file please see .less file
1. HTML Elements
2. IDs
3. Classes
4. Animations
5. Media Queries
6. Less Elements (in .less file only)
*/

/*== 1 HTML Elements ==*/
html, body, div, span, applet, object, iframe, 
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, Newsletter, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: 'myriad-pro', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'montserrat', sans-serif;
  font-weight: 700;
}

h5, h6 {
	font-family: 'montserrat', sans-serif;
	font-weight: 600;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* ==Sidebar Start== */
:root {
  --sidebar-width: 100px;
  --sidebar-bg-colour: white;
}

@media (min-width: 1000px) {
  .sidebar {
    display: none;
    position: relative;
    width: var(--sidebar-width);
    height: 0;
  }
}
@media (max-width: 767px) {

  .sidebar {
    display: none;
    position: relative;
    width: var(--sidebar-width);
    height: 100vh;
  }

  div.sidebar-is-open .sidebar {
    display: block; 
     /* 
      The sidebar is just rendered in default position,
      as it appears in the document flow
     */
  }

  div.sidebar-is-open .siteRoot {
    position: fixed; 
    /* 
     It is the main content that is positioned. 
     This is the crux of the implementation. The rest is all sugar.

     Cons: the body will scroll to the top, losing your user's scroll position
    */

    /* prevents resizing from its original full-screen width */
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%; 

    overflow: hidden;
  }

  /* Optional enhancement: 
     make the over-scroll on iPhone the same color as the sidebar */
  div.sidebar-is-open body {
    background-color: var(--sidebar-bg-colour);
  }
  .sidebar {
    background-color: var(--sidebar-bg-colour);
  }
}

/* ==Sidebar End== */

*,
*:after,
*:before {
  box-sizing: border-box;
}
a {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  color: ${theme.colors.linkColor};
  &:hover {
    color: ${theme.colors.navHighlight};
  }
}
a:hover {
  text-decoration: none !important;
}
body {
  font-family: 'montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  background-color: #FFF;
}
ul, ol {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li {
  list-style: none;
}

  /* @group montserrat
  -------------------------------------------------------------- */

  /* = Weights montserrat  
  -------------------------------------------------------------- */

  .thin {
    /* montserrat Thin = 100 */
    font-weight: 100;
  }

  .extralight {
    /* montserrat Extra Light = 200 */
    font-weight: 200;
  }


  .light {
    /* montserrat Light = 300 */
    font-weight: 300;
  }


  .regular {
    /* montserrat Regular = 400 */
    font-weight: 400;
  }


  .medium {
    /* montserrat Medium = 500 */
    font-weight: 500;
  }


  .semibold {
    /* montserrat Semi-bold = 600 */
    font-weight: 600;
  }


  .bold {
    /* montserrat Bold = 700 */
    font-weight: 700;
  }


  .extrabold {
    /* montserrat Extra Bold = 800 */
    font-weight: 800;
  }


  .black {
    /* montserrat Black = 900 */
    font-weight: 900;
  }

  /* @end montserrat group */

/*== Modal Nav ==*/
.modal-open {
  
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 !important;
  border-bottom: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  button.close {
    font-size: 3.5rem;
    margin-right: 20px;
    margin-top: 7px;
    color: ${theme.colors.white};
    opacity: 1 !important;
    font-weight: unset;
  }
}

.modal-backdrop {
  background-color: ${theme.colors.base} !important;
  &.show {
    opacity: .85 !important;
  }
}
.modal-content {
  border: 0px !important;
  background-color: transparent !important;
}

div.siteContent {
  @media (max-width: 768px) {
    margin-top: 90px;
  }
}

/*== Imports ==*/

`

export default MainStyle