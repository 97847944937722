const theme = {
  colors: {
    base: '#e34f30', // Reed's Light Orange
    secondary: '#922529', // Reed's Dark Orange
    secondaryAlt: '#153A6E', // Reed's Dark Blue
    tertiary: '#e5e5e5', // Light Gray
    quadiary: '#515153', // Dark Gray
    white: 'white',
    reedsBeige: '#e8e2d5',
    highlight: 'none', // Buttons
    navHighlight: '#153a6e', // Dark Blue
    linkColor: '#E34F30',
    baseLight: '#e38571'
  }
}

export default theme