import React from 'react'
import styled from 'styled-components'
import SubFooter from './SubFooter'
import Copyright from './Copyright'
import {StaticQuery , graphql } from 'gatsby'

const Wrapper = styled.footer`
  border-top: 6px solid ${props => props.theme.colors.base};
  background-color: ${props => props.theme.colors.tertiary};
  margin: 0 auto;
`


// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={
      graphql`
        query{
          allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
            edges {
              node {
                storeName
                localName
                addressLine1
                addressLine3
                phoneNumber
                storeHours {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      `
    }
    render={data => (
      <Wrapper>
        <SubFooter stores={data}/>
        <Copyright/>
      </Wrapper>
    )}
  />
)